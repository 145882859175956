import {React, useState,useEffect} from "react";
import axios from "axios"
import DataTable from 'react-data-table-component';
import {Chart as ChartJS,CategoryScale,LinearScale,BarElement,Title,Tooltip,Legend} from "chart.js";
import { Bar } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);





const columnsDomain = [
      {
        name: 'Sn',
        selector: row => row.id,
        sortable:true
    },
    {
        name: 'Domain',
        selector: row => row.domain,
        sortable:true
    },
    {
        name: 'Status',
        selector: row => row.status,
        sortable:true
    },
    
];

const dataDomain = [
    {
        id: 1,
        domain: 'Google.com',
        status: 'Restricted',
    },
    {
        id: 2,
        domain: 'YouTube.com',
        status: 'Restricted',
    },
    {
      id: 3,
      domain: 'Facebook.com',
      status: 'Restricted',
  },
  {
    id: 4,
    domain: 'Twitter.com',
    status: 'Restricted',
},
{
  id: 5,
  domain: 'Instagram.com',
  status: 'Restricted',
},
{
  id: 6,
  domain: 'Baidu.com ',
  status: 'Restricted',
},
{
  id: 7,
  domain: 'Wikipedia.org ',
  status: 'Restricted',
},
{
  id: 8,
  domain: 'Yandex.ru ',
  status: 'Restricted',
},
{
  id: 9,
  domain: 'Yahoo.com ',
  status: 'Restricted',
},
{
  id: 10,
  domain: 'Whatsapp.com ',
  status: 'Restricted',
},
{
  id: 11,
  domain: 'xyz.com',
  status: 'Restricted',
},
{
  id: 12,
  domain: 'test.io',
  status: 'Restricted',
},
];


// FOR CATEGORIES FOR CATEGORIES FOR CATEGORIES FOR CATEGORIES FOR CATEGORIES


const columnsCategory = [
  {
    name: 'Sn',
    selector: row => row.id,
    sortable:true
},
{
    name: 'Category',
    selector: row => row.category,
    sortable:true
},
{
    name: 'Status',
    selector: row => row.status,
    sortable:true
},

];

const dataCategory = [
{
    id: 1,
    category: 'Addictions',
    status: 'Restricted',
},
{
    id: 2,
    category: 'Ads',
    status: 'Restricted',
},
{
  id: 3,
  category: 'Adult content',
  status: 'Restricted',
},
{
id: 4,
category: 'Antivirus',
status: 'Restricted',
},
{
id: 5,
category: 'Shopping',
status: 'Restricted',
},
{
id: 6,
category: 'Social media',
status: 'Restricted',
},
{
id: 7,
category: 'Sex education',
status: 'Restricted',
},
{
id: 8,
category: 'Banking ',
status: 'Restricted',
},
{
id: 9,
category: 'File sharing sites ',
status: 'Restricted',
},
{
id: 10,
category: 'Games and humor',
status: 'Restricted',
},
{
id: 11,
category: 'Hacking',
status: 'Restricted',
},
{
id: 12,
category: 'Weapons and violence',
status: 'Restricted',
},
{
  id: 13,
  category: 'Weapons and violence',
  status: 'Restricted',
  },
  {
    id: 14,
    category: 'Weapons and violence',
    status: 'Restricted',
    },
  {
    id: 15,
    category: 'Weapons and violence',
    status: 'Restricted',
    },
    {
    id: 16,
    category: 'Weapons and violence',
    status: 'Restricted',
    },
    {
      id: 17,
      category: 'Weapons and violence',
      status: 'Restricted',
      },

];

 function Report() {

  const [searchDomain, setSearchDomain] = useState("");
  const [searchCategory, setSearchCategory] = useState("");
  const [state, setState] = useState(1);
  const [getForm, setGetForm] = useState([]);
  const [keys, setKeys] = useState(["Ads", "Banking", "Religion", "News media","Shopping", "Addictions", "Hacking", "Adult content", "Antivirus", "Social media", "Sex education", "File sharing sites", "Games and humor", "Weapons and violence", "Health home garden", "Malware Phishing",  "Search email"]);
  const [values, setValues] = useState([12, 22, 34, 47, 54, 78,73,77,64,92,57,34,91,56,82,36,44]);
  const [report, setReport] = useState({
    "file_sharing_sites": 2,
    "social_media": 16
  });

console.log("getForm",getForm);
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top" 
      },
      title: {
        display: true,
        text: "Restricted domains reach attempts "
      }
    }
  };
  
  const labels = keys;
  
   const data = {
    labels,
    datasets: [
      {
        label: "No. of attempts",
        data: values,
        backgroundColor: "#1b3e77",
      },
      // {
      //   label: "Dataset 2",
      //   data: [6, 7, 8, 9, 15],
      //   backgroundColor: "rgba(53, 162, 235, 0.5)"
      // }
    ]
  };

  useEffect(()=> {
    const user = window.localStorage.getItem('user');
    const headers = {
      'ProfileID': user,
      'X-BuddyGuard-API-Key': 'MfLgaSi$cgnet03'
  };

    axios.get('https://bknd.cgnet.com.np/reportDns',{ headers })
      .then(response => {
      
        console.log("reportDns",response.data);
        if (response.data){
          setReport(response.data)
          const [keys, values] = Object.entries(response.data).reduce((acc, [key, value]) => [          [...acc[0], key],
          [...acc[1], value]
        ], [[], []]);
        setKeys(keys);
        setValues(values);
        }
      })
      .catch(error => {
        console.log(error);
      });


       
  
   },[])


   useEffect(()=> {
    const user = window.localStorage.getItem('user');
    const headers = {
      'ProfileID': user,
      'X-BuddyGuard-API-Key': 'MfLgaSi$cgnet03'
  };

    axios.get('https://bknd.cgnet.com.np/getDns',{ headers })
      .then(response => {
        // setUserDetail(response.data);
        console.log("getDns",response.data);
      })
      .catch(error => {
        console.log(error);
      });



      axios.get('https://bknd.cgnet.com.np/getForm', { headers })
      .then(response => {
        // console.log("get", response.data);
        setGetForm(response.data)
      })
      .catch(error => {
        // console.log(error);
      });
  
  
   },[state])



   const handleClick = (id) => {
    const headers = {
      'id': id,
  };

   axios.delete('https://bknd.cgnet.com.np/delForm', {headers})
   .then(response => {
     console.log("ok")
    setState(state + 1)
  })
  .catch(error => {
     console.log(error);
  });
  };
 
  return (
    <div className="reportWrapper container">
      <Bar options={options} data={data} className="mb-5"/>

      {/* <div className="row mt-5">
         <div className="col-md-6 col-sm-12">
            <div className="datatableWrapper">
                <div className="datatableFilter">
                  <i class="fa-brands fa-searchengin"></i>
                  <input type="text" placeholder="search in domains..."></input>
                </div>
                <DataTable
                  columns={columnsDomain}
                  data={dataDomain.filter((item) => {
                    if (searchDomain === "") {
                      return item;
                    } else if (
                      item.domain.toLowerCase().includes(searchDomain.toLowerCase())
                    ) {
                      return item;
                    }
                  })}
                  // data={data1}
                  pagination="true"
                  dense
                  striped="true"
                  defaultSortAsc="true"
                  defaultSortFieldId
              />
              </div>
         </div>

         <div className="col-md-6 col-sm-12">
           <div className="datatableWrapper">
                <div className="datatableFilter">
                  <i class="fa-brands fa-searchengin"></i>
                  <input type="text" placeholder="search in category..."></input>
                </div>
                <DataTable
                  columns={columnsCategory}
                  data={dataCategory.filter((item) => {
                    if (searchCategory === "") {
                      return item;
                    } else if (
                      item.category.toLowerCase().includes(searchCategory.toLowerCase())
                    ) {
                      return item;
                    }
                  })}
                  // data={data1}
                  pagination="true"
                  dense
                  striped="true"
                  defaultSortAsc="true"
                  defaultSortFieldId
              />
              </div>
             
         </div>
      </div> */}
       {/* <div className="reportTable">
        <h1>Running Schedules:</h1>
       <table >
      <thead>
        <tr>
          <th>ID</th>
          <th>Selected Days</th>
          <th>Selected Categories</th>
          <th>Dates</th>
          <th>Schedular</th>
          <th>User</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
        {getForm.map(item => (
          <tr key={item.id}>
            <td>{item.id}</td>
            <td>{item.selectedDays}</td>
            <td>{item.selectedCategories}</td>
            <td>{item.dates}</td>
            <td>{item.schedular}</td>
            <td>{item.user}</td>
            <td><button  onClick={() => handleClick(item.id)}>Delete</button></td>
          </tr>
        ))}
      </tbody>
    </table>
       </div> */}
    </div>
  );
}

export default Report
 