
import React, { useState,useEffect,useLayoutEffect,useRef } from 'react';
import axios from "axios"
import { Col, Row, Button, Divider, Space, Tour } from 'antd';
import Header from "../components/header";
import PcSwitch from "../components/pcSwitch";
import Disabled from "../components/pcDisabled";
import Plans from "../components/plans";
import ReadOnly from '../components/readOnly';
import { useLocation } from 'react-router-dom';

function Dashboard() {
    const ref1 = useRef(null);
    const ref2 = useRef(null);
    const ref3 = useRef(null);
    // const ref4 = useRef(null);
    // const ref5 = useRef(null);
    // const ref6 = useRef(null);
    const [open, setOpen] = useState(false);
    const [status, setStatus] = useState(false);
    const [data, setData] = useState({
      active: "none",
      timezone: "Asia/Kathmandu",
      timebomb_active: false,
      timebomb_start: "",
      timebomb_end: "",
      kids_safe: false,
      homework_time: false,
      malware_protect: false,
      custom_base: [],
      custom2: {
          name: "",
          start_time: "16:00",
          end_time: "18:00",
          categories: []
      },
      custom3: {
          name: "",
          start_time: "16:00",
          end_time: "18:00",
          categories: []
      },
      whitelist: [],
      blacklist: []
  });
    const location = useLocation();
     console.log('data',data)

    const steps = [
      {
        title: 'Parental Control ON/OFF',
        description: 'Here you can set parental control on and off, try turning on.',
        target: () => ref1.current,
        placement: 'bottom',
      },
      {
        title: 'Quick Plans For You',
        description: 'You can select a plan directly from here and start blocking the web contents immidiately.',
        target: () => ref2.current,
        placement: 'right',
      },
      {
        title: 'Time Status And Schedular Status',
        description: 'Here you will see the time range of selected plans and duration of schedular upon schedule creation.',
        target: () => ref3.current,
        placement: 'right',
      },
      // {
      //   title: 'Advance',
      //   description: 'In advance menu you can schedule a plan for future use and create a schedular.',
      //   target: () => ref4.current,
      //   placement: 'bottom',
      // },
      // {
      //   title: 'Whitelist/Blacklist',
      //   description: 'In whitelist/blacklist you can add or delete a particular domain for blacklisting or whitelisting.',
      //   target: () => ref5.current,
      //   placement: 'bottom',
      // },
      // {
      //   title: 'Reports',
      //   description: 'All the generated reports are in reports menu.',
      //   target: () => ref6.current,
      //   placement: 'bottom',
      // },
    ];

    const updateStatus = (newStatus) => {
      setStatus(newStatus);
    }

    useEffect(()=> {
       if(status) {
        data.custom2.name === '' && status && data.custom3.name === '' ? setOpen(true) : setOpen(false); 
       }
    },[status])

    useLayoutEffect(() => {
      const item = window.localStorage.getItem('user');
      if(!item){
        window.location.href = "/";
      }

    }, []);

    useEffect(() => {
      const user = window.localStorage.getItem('user');
      const headers = {
        'ProfileID': user,
        'X-BuddyGuard-API-Key': 'MfLgaSi$cgnet03'
      };
  
        axios.get('https://bknd.cgnet.com.np/getDns', { headers })
        .then(response => {
          //  console.log("apidata", response.data);
          if(response.data.active === "custom"){
            setStatus(true);
            setData(response.data)
          }
         else {
            setStatus(false);
          }

          
          
        })
        .catch(error => {
          // console.log(error);
        });
  
      
    }, [])
  
    return(
        <div className="dashboard">
           {/* <div ref={ref4}>
            <div ref={ref5}>
             <div ref={ref6}>
           
             </div>
            </div>
           </div> */}
           <Header />
           {status ?<Button type="primary" onClick={() => setOpen(true)} id="tour">
        Begin Tour
      </Button> : null}
           <div ref={ref1}>
           <PcSwitch  status={status} updateStatus={updateStatus} />
            </div>
          {status ? <Row className='tablesWrapper'>
             <Col span={12} ref={ref2}><Plans /></Col> 
             <Col span={12} ref={ref3}><ReadOnly /></Col>
           </Row> : <Col span={24}><Disabled /></Col>}
           <Tour open={open} onClose={() => setOpen(false)} steps={steps} />
          
        </div>
    )
}
export default Dashboard