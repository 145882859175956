import AdvanceComponent from "../components/advance";
import Header from "../components/header";
import React, { useState,useLayoutEffect } from 'react';
import { useLocation } from 'react-router-dom';

function Advance () {
    const location = useLocation();
    useLayoutEffect(() => {
        const item = window.localStorage.getItem('user');
        if(!item){
          window.location.href = "/";
        }
      }, []);
    return(
        <div className="advancePage">
             <Header />
            <AdvanceComponent />
        
        </div>
    )
}

export default Advance