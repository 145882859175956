import React, { useState,useLayoutEffect } from 'react';
import { useLocation } from 'react-router-dom';
import ReportComponent from "../components/report";
import Header from "../components/header";

function Report() {
  const location = useLocation();
  useLayoutEffect(() => {
      const item = window.localStorage.getItem('user');
      if(!item){
        window.location.href = "/";
      }
    }, []);

    return (
       <div className="reportWrapper">
        <Header/>
         <ReportComponent />
       </div>
    );
  }
  
  export default Report;
  