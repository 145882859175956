import React, { useState,useLayoutEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Header from "../components/header";
import BlockList from "../components/blacklistWhitelist";

function BlackWhiteList() {
   const location = useLocation();
   useLayoutEffect(() => {
       const item = window.localStorage.getItem('user');
       if(!item){
         window.location.href = "/";
       }
     }, []);

    return (
       <div className="listPage">
        <Header />
        <BlockList />
       </div>
      
    );
  }
  
  export default BlackWhiteList;
  