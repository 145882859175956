import { useEffect, useState } from "react";
import Logo from "../assets/image 2.png"
import { Image } from 'antd';
import {Button, Drawer } from 'antd';
import {  SmileOutlined,MenuFoldOutlined,SettingOutlined,UnorderedListOutlined,DatabaseOutlined,QuestionCircleOutlined,ProfileOutlined    } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { LogoutOutlined  } from '@ant-design/icons';


const items = [
    {
      key: '1',
      label: (
        <a target="_blank" rel="noopener noreferrer" href="https://www.antgroup.com">
          1st menu item
        </a>
      ),
    },
    {
      key: '2',
      label: (
        <a target="_blank" rel="noopener noreferrer" href="https://www.aliyun.com">
          2nd menu item (disabled)
        </a>
      ),
      icon: <SmileOutlined />,
      disabled: true,
    },
    {
      key: '3',
      label: (
        <a target="_blank" rel="noopener noreferrer" href="https://www.luohanacademy.com">
          3rd menu item (disabled)
        </a>
      ),
      disabled: true,
    },
    {
      key: '4',
      danger: true,
      label: 'a danger item',
    },
  ];



function Header (props) {
  const [open, setOpen] = useState(false);
  const [user, setUser] = useState(false);
  const location = useLocation();
console.log("props",props)
  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const logoutHandler = () => {
    localStorage.removeItem("user");
                window.location.href = `/?username=${user}`;
  }
  useEffect(()=>{
   const user = window.localStorage.getItem('user');
   setUser(user)
  },[])
   return(
    <div className="headerWrapper flex">
       <Link to="/dashboard"><Image src={Logo} preview={false} /></Link>
      <div>
        <Button type="primary" onClick={showDrawer}>
        <MenuFoldOutlined />
        </Button>
        <Drawer title="Menu" placement="right" onClose={onClose} open={open}>
           <ul className="flex">
           <li><Link to="/dashboard"><ProfileOutlined /> Quick plans</Link></li>
             <li><Link to="/advance"><SettingOutlined /> Advance</Link></li>
             <li><Link to="/blacklist"><UnorderedListOutlined /> Whitelist/Blacklist</Link></li>
             <li><Link to="/report"><DatabaseOutlined /> Reports</Link></li>
             <li><Link to="/faq"><QuestionCircleOutlined /> FAQs</Link></li>
             <li id="logout" onClick={logoutHandler}>< LogoutOutlined /> Logout</li>
          </ul>
        </Drawer>
      </div>
       <div className="rightNav_items">
          {user ? <ul className="flex">
          <li><Link to="/dashboard"><ProfileOutlined /> Quick plans</Link></li>
             <li><Link to="/advance"><SettingOutlined /> Advance</Link></li>
             <li><Link to="/blacklist"><UnorderedListOutlined /> Whitelist/Blacklist</Link></li>
             <li><Link to="/report"><DatabaseOutlined /> Reports</Link></li>
             <li><Link to="/faq"><QuestionCircleOutlined /> FAQs</Link></li>
             <li id="logout" onClick={logoutHandler}>< LogoutOutlined /> Logout</li>
          </ul> : null}
       </div>
    </div>
   )
}

export default Header