import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Space } from 'antd';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { notification } from "antd";
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
dayjs.extend(customParseFormat);
// const { RangePicker } = DatePicker;



const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
const categories = [
  'addictions',
  'ads',
  'adult_content',
  'antivirus',
  'banking',
  'file_sharing_sites',
  'games_humor',
  'hacking',
  'health_home_garden',
  'malware_phishing',
  'news_media',
  'religion',
  'search_email',
  'sex_education',
  'shopping',
  'social_media',
  'weapons_violence'
];

const categoriesLabel = [
  'Addictions',
  'Ads',
  'Adult Content',
  'Antivirus',
  'Banking',
  'File Sharing Sites',
  'Games Humor',
  'Hacking',
  'Health Home Garden',
  'Malware Phishing',
  'News Media',
  'Religion',
  'Search Email',
  'Sex Education',
  'Shopping',
  'Social Media',
  'Weapons And Violence'
];




const Advance = () => {
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [dates, setDates] = useState([]);
  const [checked, setChecked] = useState();
  const [schedular, setSchedular] = useState("scheduler");
  const [apiResponse, setApiResponse] = useState([]);
  const [errorDates, setErrorDates] = useState(false);
  const [errorDays, setErrorDays] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [state, setState] = useState(1);
  const [getForm, setGetForm] = useState([]);
  const [catError, setCatError] = useState(false);

console.log(dates)
  const onChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };



  useEffect(() => {
    const user = window.localStorage.getItem('user');
    const headers = {
      'ProfileID': user,
      'X-BuddyGuard-API-Key': 'MfLgaSi$cgnet03'
    };

    axios.get('https://bknd.cgnet.com.np/getForm', { headers })
      .then(response => {
        let index = -1;

        for (let i = 0; i < response.data.length; i++) {
          if (response.data[i].schedular === "scheduler") {
            index = i;
            break;
          }
        }

        setSelectedCategories(JSON.parse(response.data[index].selectedCategories));
        setDates(JSON.parse(response.data[index].dates));
        // const formattedStartDate = JSON.parse(response.data[index].dates[0]);
        // const formattedEndDate = JSON.parse(response.data[index].dates[1]);

        // const startDate1 = new Date(`${formattedStartDate}T00:00:00.000Z`);
        // const endDate1 = new Date(`${formattedEndDate}T23:59:59.999Z`);

        // const isoStartDate = startDate1.toISOString();
        // const isoEndDate = endDate1.toISOString();

        // setStartDate(isoStartDate);
        // setEndDate(isoEndDate)
        // setDates(JSON.parse(response.data[index].dates));
        // console.log("get", response.data);

      })
      .catch(error => {
        // console.log(error);
      });

   

  }, [apiResponse])


  useEffect(()=>{
    const user = window.localStorage.getItem('user');
    const headers = {
      'ProfileID': user,
      'X-BuddyGuard-API-Key': 'MfLgaSi$cgnet03'
    };

    axios.get('https://bknd.cgnet.com.np/getForm', { headers })
    .then(response => {
      // console.log("get", response.data);
      setGetForm(response.data)
    })
    .catch(error => {
      // console.log(error);
    });
  },[apiResponse,state])




  const handleCategoriesChange = (e) => {
    setSelectedCategories(
      e.target.checked
        ? [...selectedCategories, e.target.value]
        : selectedCategories.filter((category) => category !== e.target.value)
    );
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    
    const formattedStartDate = startDate.toISOString().split('T')[0];
    const formattedEndDate = endDate.toISOString().split('T')[0];
    const finalDate = [formattedStartDate,formattedEndDate]
        console.log("dates",[formattedStartDate,formattedEndDate])
    let selectedCategoriesJson = JSON.stringify(selectedCategories);
    let datesJson = JSON.stringify(finalDate);




    const user = window.localStorage.getItem('user');

    const data = { selectedDays: '["all"]', selectedCategories: selectedCategoriesJson, dates: datesJson, schedular: schedular };
    console.log("submit", data)
    const headers = {
      'ProfileID': user,
      'X-BuddyGuard-API-Key': 'MfLgaSi$cgnet03'
    };

    if(selectedCategories.length === 0) {
        setCatError(true);
    }else {
     setCatError(false)

      axios.post('https://bknd.cgnet.com.np/form', data, { headers })
      .then(response => {

        let receivedFormData = response.data;
        const updatedData = receivedFormData.map(item => {
          return {
            selectedDays: JSON.parse(item.selectedDays),
            selectedCategories: JSON.parse(item.selectedCategories),
            schedular: item.schedular,
            dates: JSON.parse(item.dates)
          };
        });
        setApiResponse(updatedData);
        setErrorDays(false);
        setErrorDates(false);
        notification.open({
          message: 'Success',
          type: "success",
          description: 'Update Successful',
          onClick: () => {
            console.log('Notification Clicked!');
          },
        });
      })
      .catch(error => {
        console.log(error);
      });
    }


   

     
  };


  const handleClick = (id) => {
    const headers = {
      'id': id,
  };

   axios.delete('https://bknd.cgnet.com.np/delForm', {headers})
   .then(response => {
     console.log("ok")
    setState(state + 1)
  })
  .catch(error => {
     console.log(error);
  });
  };







  return (
    <div className='advanceForm'>
      <div className="readOnlyWrapper">
       
        <form onSubmit={handleSubmit}>
          
          <div className="timeStatus bgWhite">
     
        <h1>Schedule Custom Plan:</h1>
            <div className='plannerWrapper'>

              <div>
              <DatePicker
                selected={startDate}
                onChange={onChange}
                startDate={startDate}
                endDate={endDate}
                selectsRange
                inline
              />
               {/* <h3>{dates.length !== 0 ? "Scheduled: "+dates[0] + ' ' + ' / ' +dates[1] : null}</h3> */}
         
              </div>
             
              
              <ul className="ks-cboxtags">
                <h1>Select Categories</h1>
                 <div>
                 {categories.map((category) => (

<li key={category}><input type="checkbox" id={category} value={category} onChange={handleCategoriesChange} checked={selectedCategories.includes(category) ? true : null} className={checked ? 'checkbox-checked' : 'checkbox-unchecked'} /><label htmlFor={category}>  {category}</label></li>


))}
                 </div>
                 {catError? <p className='error'>Atleast one category must be selected.</p>:null}
              </ul>

            </div>
          <div className='realTime'>
          <p>   {`Start Date: ${ String(startDate).slice(0, 15)}`}</p>
            <p>{` End Date: ${String(endDate).slice(0, 15)}`}</p>
          </div>
            <div className='responsive'>
            <h1>Running Schedules:</h1>
       <table >
      <thead>
        <tr>
        
          {/* <th>Selected Days</th> */}
          <th>Selected Categories</th>
          <th>Start Date</th>
          <th>End Date</th>
          {/* <th>Schedular</th> */}
          <th>User</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
        {getForm.map(item => (
          <tr key={item.id}>
  
            {/* <td>{item.selectedDays}</td> */}
            <td>{item.selectedCategories !== "[]" ? item.selectedCategories : "No categories selected"}</td>
            <td>{item.dates.slice(2, 12)}</td>
            <td>{item.dates.slice(15, 25)}</td>
            {/* <td>{item.schedular}</td> */}
            <td>{item.user}</td>
            <td><span className='dangerBtn' onClick={() => handleClick(item.id)}>Delete</span></td>
          </tr>
        ))}
      </tbody>
    </table>
            </div>
            <button type="submit" className='applyBtn'>Apply</button>
          </div>

          
        </form>
      </div>
    </div>
  )
};

export default Advance;