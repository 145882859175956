import { BrowserRouter as Router, Route,  Routes } from "react-router-dom";

import Home from "../themes/Home";
import Dashboard from "../themes/Dashboard";
import Advance from "../themes/Advance";
import Report from "../themes/Report";
import BlackWhiteList from "../themes/BlackWhiteList";
import Faq from "../themes/Faq";

function MyRoutes() {
  return (
    <div className="Routes">
      <Router>
        <Routes>
          <Route exact path="/" element={<Home />}/>
          <Route exact path="/dashboard" element={<Dashboard />}/>
          <Route exact path="/advance" element={<Advance />}/>
          <Route exact path="/blacklist" element={<BlackWhiteList />}/>
          <Route exact path="/report" element={<Report />}/>
          <Route exact path="/faq" element={<Faq />}/>
        </Routes>
       
      </Router>
    </div>
  );
}

export default MyRoutes;