import shield from "../assets/Warning Shield.svg"
import {Image} from "antd"
import {PoweroffOutlined} from '@ant-design/icons'
import Watch from "./watch";


function disabled () {
    return(
        <div className="pcDisabled">
            <Watch />
            <div className="shieldHolder">
            <PoweroffOutlined />
            </div>
            <h4 className="j-center">PARENTAL CONTROL IS DISABLED</h4>
            {/* <span>Enable parental control to restrict access to websites.</span> */}
        </div>
    )
}

export default disabled