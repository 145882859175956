import { Collapse } from 'antd';
import Header from '../components/header';
const { Panel } = Collapse;
const text = `
CG NET Parental Control is the family filter that provides a non-intrusive first line of defense for your home against the more unpleasant corners of the internet.
When CG NET Parental Control is on it protects your family against: 
•	Pornography, and nudity
•	Known hacked or infected websites
•	Dating, drugs, gambling, alcohol, tobacco sites
•	Websites about hacking, the dark web, and other illegal activity
•	Suicide and self-harm sites

`;

const test1 =
`When CG NET Parental Control is enabled, any material that fits the restrictions above will be filtered from any devices connected to your internet, including phones and tablets connected via your Wi-Fi at your home Optical Network Unit (ONU) router provided by CG Net. This will only work under CG Net network environment but you can control it from any network where the internet is available.
 
All filtering is done in our network so you don’t have to load any software on your devices. Even your guest WiFi enabled devices are protected till the time they are connected, in all CG NET Parental Control connected at your home.

`;
const test2 =
<>Login to CG Net Customer Self-Care Portal (<a href='https://customer.cgnet.com.np'>https://customer.cgnet.com.np</a>) then Click on Parental Control under main Menu on Left Side bar where you can turn it on easily.
</>;
const test3 =
`Our first objective is to ensure that the internet taken at home should be used for the well being of the family. Hence, being a service provider, we are not charging anything as of now.`;

const test4 = 
`No, CG NET Parental Control will not interfere with your internet speeds.  We only stop the websites which need to be stopped. This is purely customer owned, customer operated service.  `;
const test5 = 
`Family first!! Always!!  We help you decide what’s right for your family. CG NET Parental Control helps you choose the online content by filtering out adult content, social media or anything undesirable should you need to. It's simple to use with a range of per-configured settings that you can customize to suit your family. So, we’re working to help you set boundaries and use technology in a way that’s right for your family.
As this is paired with your broadband connection, it applies to every device connected to your home network, making the internet a safer place for everyone.

`;

const test6 = 
`We often tend to get generic queries around security and spying in our mind when it comes to parental controls. Generic questions like “Can my kids or family members or guests somehow get around it?”
Here is some information that should hopefully answer most of your questions, and put your mind at ease about how CG NET Parental Control works and what its benefits are.
We all are concerned about our society and our community, and believe that there should be ways in which we can protect them and also educate them easily and affordably.
As we are sure you have already seen and agree that the internet is full of a whole range of things, some of which are less than savory.   And as an Internet service provider, CG Net looks on as a Digital parent by positioning a CG NET Parental Control product and giving control power to the end customer. Moreover, we have made it as simple as possible for you to achieve the outcome you desire.

`;

const test7 =
`We have built all of the CG NET Parental Control features into your Parental Control Self-Care homepage, and made it as easy as possible. 
There are categories of websites grouped together by type, and you can turn on/enable Quick Plan like “Family Time” to filter out each category, or leave it off to let that category through. 

We have even added the ability to block and allow custom domains as per your conveniences which can be add/delete as per your requirements

`;
const test8 = 
`Your privacy is of utmost importance to us. You can rest assured that CG NET Parental Control is not spying on your connection. Moreover, we are not logging any of your transactions. It performs the same function as a DNS service - it receives requests from you to direct traffic, and it will respond to you with the website or a special splash page in case the desired website is blocked by you.

I have blocked social media and I can see certificate warnings in the browser when I am trying to open a website related to social media. Why is this?

It’s happening because the website you have requested is blocked by the category and the browser is expecting the certificate from the website that you are trying to access. 

This is fully expected given the way in which browser security is designed. The warnings and browser behavior vary slightly between Chrome, Safari, and Firefox. Once you allow it, it will redirect it to the CG NET Parental Control page or show Page cannot be displayed.
`;

const test9 = <>Here are the Quick Plans provided by CG NET Parental Control:<br/>
I. Child Safety: 
<br/>
'ads','adult_content','banking','file_sharing_sites','hacking','malware_phishing','news_media','religion','sex_education','shopping','social_media','weapons_violance’
<br/>
<br/>
II. Office Hours:
<br/>
'ads','adult_content','hacking','malware_phishing','sex_education','shopping','social_media','weapons_violance'
<br/>
<br/>
III. Study Hour:
<br/>
'ads','adult_content','banking','file_sharing_sites','games_humor','hacking','malware_phishing','news_media','religion','sex_education','shopping','social_media','weapons_violance'
<br/>
<br/>
IV. Family Time:
<br/>
'ads','adult_content','banking','hacking','malware_phishing','news_media','religion','search_email','sex_education','weapons_violance'
<br/>
<br/>
V. Goodnight Mode:
<br/>
'adictions','ads','adult_content','banking','file_sharing_sites','hacking','malware_phishing','sex_education','shopping','social_media','weapons_violance'
<br/>
<br/>
VI. Block All: 
<br/>'adictions','ads','adult_content','antivirus','banking','file_sharing_sites','games_humor','hacking','health_home_garden','malware_phishing','news_media','religion','search_email','sex_education','shopping','social_media','weapons_violance'
</>;
const test10 = <>1.	"addictions" = Domains related to addictions are restricted<br/>
2.	"ads" = Domains related to ads are restricted<br/>
3.	"adult_content" = Domains related to adult_content are restricted<br/>
4.	"antivirus" = Domains related to antivirus are restricted<br/>
5.	"banking" = Domains related to banking are restricted<br/>
6.	"file_sharing_sites" = Domains related to file_sharing_sites are restricted<br/>
7.	"games_humor" = Domains to games_humor are restricted<br/>
8.	"hacking" = Domains related to hacking are restricted<br/>
9.	"health_home_garden" = Domains related to health_home_garden are restricted<br/>
10.	"malware_phishing" = Domains related to malware_phishing are restricted<br/>
11.	"news_media" = Domains related to news_media are restricted<br/>
12.	"religion" = Domains to religion are restricted<br/>
13.	"search_email" = Domains related to search_email are restricted<br/>
14.	"sex_education" = Domains related to sex_education are restricted<br/>
15.	"shopping" = Domains related to shopping are restricted<br/>
16.	"social_media" = Domains related to social_media are restricted<br/>
17.	"weapons_violence" = Domains related to weapons_violence are restricted<br/>
</>

const Faq = () => {
  const onChange = (key) => {
    console.log(key);
  };
  return (
    <div className='faq'>
        <Header />
    <Collapse defaultActiveKey={['1']} onChange={onChange}>
      <Panel header="What is CG NET Parental Control?" key="1">
        <p>{text}</p>
      </Panel>
      <Panel header="How does CG NET Parental Control work?" key="2">
        <p>{test1}</p>
      </Panel>
      <Panel header="How do I enable CG NET Parental Control?" key="3">
        <p>{test2}</p>
      </Panel>
      <Panel header="How much does CG NET Parental Control cost?" key="4">
        <p>{test3}</p>
      </Panel>
      <Panel header="Will CG NET Parental Control affect my speed?" key="5">
        <p>{test4}</p>
      </Panel>
      <Panel header="Why do I need CG NET Parental Control?" key="6">
        <p>{test5}</p>
      </Panel>
      <Panel header="How does CG NET Parental Control actually work?" key="7">
        <p>{test6}</p>
      </Panel>
      <Panel header="How do I set it up?" key="8">
        <p>{test7}</p>
      </Panel>
      <Panel header="Are you spying on me? Do you record information about me?" key="9">
        <p>{test8}</p>
      </Panel>
      <Panel header="What categories are there in each Quick Plans of the CG NET Parental Control?" key= "10">
        <p>{test9}</p>
      </Panel>
      <Panel header="What does each category mean?" key="11">
        <p>{test10}</p>
      </Panel>
    </Collapse>
    </div>
  );
};
export default Faq;