import { useState,useEffect,useLayoutEffect } from "react";
import { Switch,notification  } from "antd";
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import axios from "axios";


// FUNCTIONAL COMPONENT STARTS

function PcSwitch({status,updateStatus}) {

  const [structure, setStructure] = useState({
    active: "none",
    timezone: "Asia/Kathmandu",
    timebomb_active: false,
    timebomb_start: "",
    timebomb_end: "",
    kids_safe: false,
    homework_time: false,
    malware_protect: false,
    custom_base: [],
    custom2: {
        name: "",
        start_time: "16:00",
        end_time: "18:00",
        categories: []
    },
    custom3: {
        name: "",
        start_time: "16:00",
        end_time: "18:00",
        categories: []
    },
    whitelist: [],
    blacklist: []
});
  const [user, setUser] = useState("");
  const [active, setActive] = useState("none");
  const [kidsSafe, setKidsSafe] = useState(false);
  const [switchStatus, setSwitchStatus] = useState(false);
//  console.log("home",structure);
//  console.log(user);

  // SETTING VALUES IN EMPTY OBJECT FOR API REQUEST
  structure.active = active;
  structure.kids_safe = kidsSafe;


  useEffect(() => {
    
    const user = window.localStorage.getItem('user');
    setUser(user);
    const headers = {
      'ProfileID': user,
      'X-BuddyGuard-API-Key': 'MfLgaSi$cgnet03'
    };

      axios.get('https://bknd.cgnet.com.np/getDns', { headers })
      .then(response => {
        // console.log("apidata", response.data);
         setStructure(response.data)
        if(response.data.active === "custom"){
          setActive("custom");
        }
        
      })
      .catch(error => {
        // console.log(error);
      });

    
  },[])



  //useEffect
  // useEffect(()=>{
  //   const item = window.localStorage.getItem('user');
    
  // },[])

  
 
    const onChange = (checked) => {
        updateStatus(checked);
        
       
        if (checked) {
             setActive("custom");
             setKidsSafe(true);
           } else {
             setActive("none");
             setKidsSafe(!true);
           }
           

           async function greet() {
            try {
              const item = window.localStorage.getItem('user');
            
              const headers = {
                'ProfileID': item,
                'X-BuddyGuard-API-Key': 'MfLgaSi$cgnet03'
              };
              // const response = await axios.get('https://bknd.cgnet.com.np/getDns',  { headers });
              // // console.log("gggg",structure);
              // if (response.status === 200) {
              //   setStructure(response.data)
              //   notification.open({
              //     message: 'Success',
              //     type: "success",
              //     description: 'get Successfully',
              //     onClick: () => {
              //       console.log('Notification Clicked!');
              //     },
              //   });
              // }
           
            } catch (error) {
              // console.log(error);
            }
          }
          
           setTimeout(greet, 10);




           async function greet1() {
            try {
              const item = window.localStorage.getItem('user');
            
              const headers = {
                'ProfileID': item,
                'X-BuddyGuard-API-Key': 'MfLgaSi$cgnet03'
              };
              const response = await axios.post('https://bknd.cgnet.com.np/postDns',structure,  { headers });
              // console.log("pppp",structure);
              if (response.status === 200) {
                // notification.open({
                //   message: 'Success',
                //   type: "success",
                //   description: 'Update Successful',
                //   onClick: () => {
                //     console.log('Notification Clicked!');
                //   },
                // });
              }
           
             
            } catch (error) {
              // console.log(error);
            }
          }
          
           setTimeout(greet1, 1000);
        // console.log(`switch to ${checked}`);
      };


      // useEffect(() =>{
    
       
      // },[active])
     
    return(
        <div className="pcSwitchWrapper">
        
           <h1>Welcome to CGNet Digital Wellbeing - {user}</h1>
           <div>
            <label htmlFor="pcSwitch">
                Parental control
              <Switch title='Turn switch on/off to enable/disable parental control' checkedChildren={<CheckOutlined />} unCheckedChildren={<CloseOutlined />} onChange={onChange} id="pcSwitch" value="basic" checked={active === "none"? false :true }/>
            </label>
         
           </div>
        </div>
    )
}

export default PcSwitch