import Day from "../assets/Wb sunny.svg";
import Night from "../assets/Vector.svg";
import React, { useState, useEffect } from "react";
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import axios from "axios";





function ReadOnly(props) {
  const [timeRanges, setTimeRanges] = useState([]);
  const [timeRanges1, setTimeRanges1] = useState([]);
 const [rProps,setRprops] = useState(props)
  const [color, setColor] = useState("");
  const [user, setUser] = useState("");
  const [color1, setColor1] = useState("");
  const [getForm, setGetForm] = useState([]);
  const [def, setDef] = useState("");
  const [structure, setStructure] = useState({
    custom2: {
      name: "",
      start_time: "00:00",
      end_time: "00:00",
      categories: []
    },
    custom3: {
      name: "",
      start_time: "",
      end_time: "",
      categories: []
    },
    whitelist: [],
    blacklist: []
  });
  // console.log("from", fromm);
  // console.log("to", too);
  //  console.log(" color",color);
  //  console.log("dd",structure);
  //  console.log("calendar data",getForm);

  
  useEffect(() => {
    const intervalId = setInterval(() => {
      const user = window.localStorage.getItem('user');
      const headers = {
        'ProfileID': user,
        'X-BuddyGuard-API-Key': 'MfLgaSi$cgnet03'
      };
  
  
      axios.get('https://bknd.cgnet.com.np/getDns', { headers })
        .then(response => {
          setStructure(response.data)
        })
        .catch(error => {});
    }, 600);
  
    return () => {
      clearInterval(intervalId);
    };
  }, []);


  useEffect(() => {

      const user = window.localStorage.getItem('user');
      const headers = {
        'ProfileID': user,
        'X-BuddyGuard-API-Key': 'MfLgaSi$cgnet03'
      };
  
      axios.get('https://bknd.cgnet.com.np/getForm', { headers })
        .then(response => {
          setGetForm(response.data)
        })
        .catch(error => {});
  
    
  }, []);
 
  

  // const events = [
  //   { title: "knkn", start: new Date().toISOString().split('T')[0], end: new Date("2023-2-12"),backgroundColor: "rgb(0, 253, 40,1)"},
  //   // { title: 'Office Hours', start: new Date().toISOString().split('T')[0], end: new Date("2023-2-12"),backgroundColor: "rgb(255, 153, 0,.5)"},
  //   // { title: 'Study Hour', start: new Date().toISOString().split('T')[0], end: new Date("2023-2-12"),backgroundColor: "rgb(255, 230, 0,.5)"},
  //   //  { title: 'Family Time', start:new Date().toISOString().split('T')[0], end: new Date("2023-2-12"),backgroundColor: "rgb(76, 129, 214,.5)"},
  //   //  { title: 'Goodnight Mode', start:new Date().toISOString().split('T')[0], end: new Date("2023-2-12"),backgroundColor: "rgb(175, 210, 180,.5)"},
  //   //  { title: 'Block All', start:new Date().toISOString().split('T')[0], end: new Date("2023-2-12"),backgroundColor: "rgb(255, 112, 223,.5)"}
  // ]


 

 
  
    let events = getForm.map(obj => {
      const dates = JSON.parse(obj.dates);
      // const schedular = obj.schedular;
   
      let backgroundColor;
      let schedular;
      const initialDateStr = dates[1];
      const initialDate = new Date(initialDateStr);
      const oneDay = 12 * 60 * 60 * 1000; // number of milliseconds in a day
const newDate = new Date(initialDate.getTime() + oneDay);
      switch (obj.schedular) {
        case "child safety":
          backgroundColor = "#00FD28";
          schedular = "Child Safety";
          break;
        case "office hour":
          backgroundColor = "#FF9900";
          schedular = "Office Hour";
          break;
        case "study hour":
          backgroundColor = "#FFE600";
          schedular = "Study Hour";
          break;
        case "family time":
          backgroundColor = "#4C81D6";
          schedular = "Family Time";
          break;
        case "goodnight mode":
          backgroundColor = "#AFD2B4";
          schedular = "Goodnight Mode";
          break;
        case "block all":
          backgroundColor = "#FF70DF";
          schedular = "Block All";
          break;
          case "scheduler":
          backgroundColor = "#ccc";
          schedular = "Scheduler";
          break;
        default:
          backgroundColor = "#fff";
      }
      return {
        title: schedular,
        start: dates[0],
        end: newDate ,
        backgroundColor: backgroundColor
      };
    });
    // console.log("schedular", events);
  
   


 


  const hoursDay = [
    { value: 0, label: "0" },
    { value: 1, label: "1" },
    { value: 2, label: "2" },
    { value: 3, label: "3" },
    { value: 4, label: "4" },
    { value: 5, label: "5" },
    { value: 6, label: "6" },
    { value: 7, label: "7" },
    { value: 8, label: "8" },
    { value: 9, label: "9" },
    { value: 10, label: "10" },
    { value: 11, label: "11" },
    { value: 12, label: "12" },
    { value: 13, label: "13" },
    { value: 14, label: "14" },
    { value: 15, label: "15" },
    { value: 16, label: "16" },
    { value: 17, label: "17" },
    { value: 18, label: "18" },
    { value: 19, label: "19" },
    { value: 20, label: "20" },
    { value: 21, label: "21" },
    { value: 22, label: "22" },
    { value: 23, label: "23" },
   
  ]
  const hoursDay1 = [
    { value: 0, label: "0" },
    { value: 1, label: "1" },
    { value: 2, label: "2" },
    { value: 3, label: "3" },
    { value: 4, label: "4" },
    { value: 5, label: "5" },
    { value: 6, label: "6" },
    { value: 7, label: "7" },
    { value: 8, label: "8" },
    { value: 9, label: "9" },
    { value: 10, label: "10" },
    { value: 11, label: "11" },
    { value: 12, label: "12" },
    { value: 13, label: "13" },
    { value: 14, label: "14" },
    { value: 15, label: "15" },
    { value: 16, label: "16" },
    { value: 17, label: "17" },
    { value: 18, label: "18" },
    { value: 19, label: "19" },
    { value: 20, label: "20" },
    { value: 21, label: "21" },
    { value: 22, label: "22" },
    { value: 23, label: "23" }
  ]




  useEffect(() => {
    if (structure.custom2.name === "child safety") {
      setColor("colCs");
    } if (structure.custom3.name === "child safety") {
      setColor1("colCs");
    } if (structure.custom2.name === "office hour") {
      setColor("colOh");
    } if (structure.custom3.name === "office hour") {
      setColor1("colOh");
    } if (structure.custom2.name === "study hour") {
      setColor("colSh");
    } if (structure.custom3.name === "study hour") {
      setColor1("colSh");
    } if (structure.custom2.name === "family time") {
      setColor("colFt");
    } if (structure.custom3.name === "family time") {
      setColor1("colFt");
    } if (structure.custom2.name === "goodnight mode") {
      setColor("colGm");
    } if (structure.custom3.name === "goodnight mode") {
      setColor1("colGm");
    } if (structure.custom2.name === "block all") {
      setColor("colBa");
    } if (structure.custom3.name === "block all") {
      setColor1("colBa");
    }
    if (structure.custom2.name === "scheduler") {
      setColor("colSc");
    } if (structure.custom3.name === "scheduler") {
      setColor1("colSc");
    }
    

  })


  useEffect(() => {
    if (structure.custom2) {
      setTimeRanges([{ from: structure.custom2.start_time.slice(0, 2), to: structure.custom2.end_time.slice(0, 2), color:structure.custom2.name !== '' ? color : "#fff"}]);
    }
  }, [structure]);

  useEffect(() => {
    if (structure.custom3) {
      setTimeRanges1([{ from: structure.custom3.start_time.slice(0, 2), to: structure.custom3.end_time.slice(0, 2), color: structure.custom3.name !== '' ? color1 : "#fff"}]);
    }
  }, [structure]);



  function renderEventContent(eventInfo) {
    return (
      <>
        <i>{eventInfo.event.title}</i>
      </>
    )
  }



  

  useEffect(() => {
    const button = document.getElementsByClassName("fc-today-button");
   
    button[1].disabled = false;
    function todayDateEvent() {
   
       button[1].click();
    
  }
  
  setTimeout(todayDateEvent, 100);

   
  },[]);


  return (
    <div className="readOnlyWrapper">
      <div className="timeStatus bgWhite">
        <h4 className="bgBlue textWhite">Time Status</h4>
        <div>
          <div className="timeStatus_wrapper">
            <div className="singleTime_status">
              <div className="timeTitle">{structure.custom2.name ? structure.custom2.name : "Plan 1"}</div>
              <div className="timeFrame">
                <div>
                  {hoursDay.map((hourDay) => {
                    let className = 'hour-box';

                    timeRanges.forEach(timeRange => {
                      // console.log(timeRange.to)
                      if (hourDay.value >= timeRange.from && hourDay.value <= timeRange.to) {
                        className += ` ${timeRange.color}`;
                      }
                    });
                    return <div key={hourDay.value} className={className}>{hourDay.label}</div>;
                  })}
                </div>
              </div>
            </div>
            {/* <div  className="singleTime_status">
              {hoursNight.map((hourNight) => {
                let className = 'hour-box';
                
                timeRanges.forEach(timeRange => {
                  // console.log(timeRange.to)
                  if (hourNight.value >= timeRange.from && hourNight.value <= timeRange.to) {
                    className += ` ${timeRange.color}`;
                  }
                });
                return <div key={hourNight.value} className={className}>{hourNight.label}</div>;
              })}
              </div> */}
          </div>
          <div className="timeStatus_wrapper slot2">
            <div className="singleTime_status">
              <div className="timeTitle">{structure.custom3.name ? structure.custom3.name : "Plan 2"}</div>
              <div className="timeFrame">
                <div>
                  {hoursDay1.map((hourDay1) => {
                    let className = 'hour-box';

                    timeRanges1.forEach(timeRange1 => {
                      // console.log(timeRange1.to)
                      if (hourDay1.value >= timeRange1.from && hourDay1.value <= timeRange1.to) {
                        className += ` ${timeRange1.color}`;
                      }
                    });
                    return <div key={hourDay1.value} className={className}>{hourDay1.label}</div>;
                  })}
                </div>
              </div>
            </div>
            {/* <div  className="singleTime_status">
              {hoursNight1.map((hourNight1) => {
                let className = 'hour-box';
                
                timeRanges1.forEach(timeRange1 => {
                  // console.log(timeRange1.to)
                  if (hourNight1.value >= timeRange1.from && hourNight1.value <= timeRange1.to) {
                    className += ` ${timeRange1.color}`;
                  }
                });
                return <div key={hourNight1.value} className={className}>{hourNight1.label}</div>;
              })}
              </div> */}
          </div>
        </div>
      </div>
      <div className="calenderStatus bgWhite">
        <h4 className="bgBlue textWhite">Calender Status</h4>
        <div>
          <FullCalendar
            plugins={[dayGridPlugin]}
            initialView='dayGridMonth'
            weekends={true}
            events={events}
            eventContent={renderEventContent}
          />
          {/* <div className="d-flex">
    
       <FullCalendar
        plugins={[dayGridPlugin]}
        initialView='dayGridWeek'
        weekends={true}
        events={events}
        eventContent={renderEventContent}
      />
     </div> */}
        </div>

      </div>


    </div>
  )
}

export default ReadOnly