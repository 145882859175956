import { useState,useEffect } from 'react';
import axios from "axios";
import { Button, notification, Form, Input,Image } from 'antd';
import svg from "../assets/grids.svg";
import { useLocation } from 'react-router-dom';




function Login() {
    const [otp, setOtp] = useState("");
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    // const [param, setParam] = useState("cg_thapathali");
    const [apiData, setApiData] = useState({});
    const [param, setParam] = useState(params.get('username'));
    const [error, setError] = useState(false);
    
     console.log(otp.otp)
    // alert(param)
    // console.log("API",apiData)

    //useEffect useEffect useEffect useEffect

    // useEffect(() => {
      
    //   }, [otp]);

      useEffect(() =>{
            if (otp.otp === apiData) {
                localStorage.setItem("user", param);
                window.location.href = "/dashboard";
              }
              //  else {
              // if(otp.otp){
              //   setError(true);
              // }
                  
               
              // }
        
      },[apiData,error,otp])

    const onFinish = (values) => {
        setOtp(values);
        setError(false);

        const requestBody = {
          Request: {
            requestDate: "2023-01-19T20:55:00.000Z",
            extTransactionId: "47437799",
            systemId: "funnelapi",
            password: "funnel@P1"
          },
          parameter: {
            Username: param
          }
        };
    
        axios.post('https://billing.cgnet.com.np/h8ssrms/api/h8Integration.asmx/ParentControlCustomerValid', requestBody)
          .then(response => {
            setApiData(response.data.d.Pin);
            if(values.otp !== response.data.d.Pin) {
              setError(true);
            }
             // console.log("API",apiData)
            
          })
          .catch(error => {
            setApiData(error);
           
          });
      };
      const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
      };

    

    return(
        <div className="loginWrapper">
            <div className='flex'>
                <Image src={svg}/>
              <div className='otpWrapper'>
                <Form
                        name="basic"
                        labelCol={{
                            span: 8,
                        }}
                        wrapperCol={{
                            span: 16,
                        }}
                        initialValues={{
                            remember: true,
                        }}
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        autoComplete="off"
                        >
                
                        <Form.Item
                            label="Pin"
                            name="otp"
                            rules={[
                            {
                                required: true,
                                message: 'Please input your pin!',
                            },
                            ]}
                        >
                            <Input.Password />
                        </Form.Item>

                        {error === true ? <span className='redError'>Invalid PIN</span> : ''}

                        <Form.Item
                            wrapperCol={{
                            offset: 8,
                            span: 16,
                            }}
                        >
                          
                            <Button type="primary" htmlType="submit" role="button">
                            Login
                            </Button>
                           
                        </Form.Item>
                    </Form>
                </div>
            </div>
        </div>
    )
}
export default Login