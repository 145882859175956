import React, { useState,useLayoutEffect } from 'react';
import { Col, Row, Switch,notification } from 'antd';
import { Space } from "antd";
import { ProfileOutlined } from '@ant-design/icons';
import { useEffect } from 'react';
import axios from "axios";


let nextId = 0;


function BlockList() {
  const [user, setUser] = useState('');
  const [name, setName] = useState('');
  const [nameBlack, setNameBlack] = useState('');
  const [artists, setArtists] = useState([]);
  const [artistsBlack, setArtistsBlack] = useState([]);
  const [errorWhitelist, setErrorWhitelist] = useState("");
  const [errorBlacklist, setErrorBlacklist] = useState("");
  const [sameDomain, setSameDomain] = useState(false);
  const [sameDomainB, setSameDomainB] = useState(false);
  const [change, setChange] = useState(1);
  const [structure, setStructure] = useState({
    active: "none",
    timezone: "Asia/Kathmandu",
    timebomb_active: false,
    timebomb_start: "",
    timebomb_end: "",
    kids_safe: false,
    homework_time: false,
    malware_protect: false,
    custom_base: [],
    custom2: {
        name: "",
        start_time: "16:00",
        end_time: "18:00",
        categories: []
    },
    custom3: {
        name: "",
        start_time: "16:00",
        end_time: "18:00",
        categories: []
    },
    whitelist: [],
    blacklist: []
});
  // console.log('artist', artists);
  // console.log('name', name);
   // console.log('artistBlack', artistsBlack);
  // console.log('nameBlack', nameBlack);
  // console.log("WB", structure)

  // SETTING VALUES IN EMPTY OBJECT FOR API REQUEST
 useEffect(()=>{
  structure.whitelist = artists;
  structure.blacklist = artistsBlack;
 },[artists,artistsBlack])

   useEffect(() => {
    const user = window.localStorage.getItem('user');
    const headers = {
      'ProfileID': user,
      'X-BuddyGuard-API-Key': 'MfLgaSi$cgnet03'
    };

      axios.get('https://bknd.cgnet.com.np/getDns', { headers })
      .then(response => {
        // console.log("apidata2", response.data);
        setStructure(response.data)
        setArtists(response.data.whitelist);
        setArtistsBlack(response.data.blacklist);
       
      })
      .catch(error => {
        // console.log(error);
      });

    
  }, [change])



  const whiteListHandler = (e) => {
    setName(e.target.value)
    
    // Define a regular expression to match domains or URLs
    const pattern = /^(https?:\/\/)?(www\.)?([a-z0-9-]+\.)*[a-z0-9-]+\.[a-z]+(\/.*)?$/i;

    // Test the input value against the pattern
    const isValid = pattern.test(e.target.value);

    setErrorWhitelist(isValid ? "Valid" : "Please enter a valid domain or URL");
    
  }
  const blackListHandler = (e) => {
    setNameBlack(e.target.value)

     // Define a regular expression to match domains or URLs
     const pattern = /^(https?:\/\/)?(www\.)?([a-z0-9-]+\.)*[a-z0-9-]+\.[a-z]+(\/.*)?$/i;

     // Test the input value against the pattern
     const isValid = pattern.test(e.target.value);
 
     setErrorBlacklist(isValid ? "Valid" : "Please enter a valid domain or URL");
  }
  const removeListHandler = (e) => {
    // console.log("eventNAme", e.target.name);
    let removeValue = (e.target.name);
    setArtists(artists.filter(item => item !== removeValue));
    setTimeout(greet, 1000);
  }
  const removeBlackListHandler = (e) => {
    // console.log("eventNAmeBlack", e.target.name);
    let removeValueBlack = (e.target.name);
    setArtistsBlack(artistsBlack.filter(item => item !== removeValueBlack));
    setTimeout(greet, 1000);
  }

  
 
   async function greet() {
   
    if(artistsBlack.includes(name)){
   setSameDomain(true);
   setName("");
   setChange(change + 1);
    }
    else if(artists.includes(nameBlack)){
      setSameDomainB(true);
      setNameBlack("");
      setChange(change + 1);
       }
    else {
      const item = window.localStorage.getItem('user');
      const requestBody = { structure};
      const headers = {
        'ProfileID': item,
        'X-BuddyGuard-API-Key': 'MfLgaSi$cgnet03'
    };
  
    await  axios.post('https://bknd.cgnet.com.np/postDns',requestBody.structure, { headers },)
        .then(response => {
          // console.log("apidatapost",response);
          // notification.open({
          //   message: 'Success',
          //   type: "success",
          //   description: 'Update Successful',
          //   onClick: () => {
          //     console.log('Notification Clicked!');
          //   },
          // });
        })
        .catch(error => {
          console.log(error);
        });
    }
      
  }
  


  return (
    <div className="blacklistMenuWrapper">
      <div className="container">
        <div className="row">
          <div className='dashboard advanceComponent'>
            {sameDomain ? <p className='error'>Requested domain exist on blacklist, please remove it from blacklist & try again</p> : null}
            {sameDomainB ? <p className='error'>Requested domain exist on whitelist, please remove it from whitelist & try again</p> : null}
            <Row className='tablesWrapper'>
              <Col span={12}>

                <div className="plansWrapper bgWhite">
                  <h4 className="textWhite bgBlue"><ProfileOutlined /><Space>Whitelist Your Domain</Space></h4>
                  <div className="antdAccordionWrapper">
                    <div className="col-md-6">

                      <div className="domainInput">
                        <form>
                          <input type="url" placeholder="Enter the domain to be whitelisted (eg: example.com)" value={name}
                            onChange={whiteListHandler} />
                            {errorWhitelist !== "Valid" ?<span style={{ color: "red" }}>{errorWhitelist}</span>: null}
                            {errorWhitelist === "Valid" ?<span style={{ color: "green" }}>{errorWhitelist}</span>: null}
                            {errorWhitelist === "Valid" ?  <button type="button" className='applyBtn' onClick={() => {
                            if (name.length > 0) {
                              setSameDomain(false);
                              setSameDomainB(false);
                              setName('');
                              setArtists([
                                ...artists,
                                name
                              ]);
                            }
                            setTimeout(greet, 1000);
                          }}>Save</button>: null}
                        </form>

                        <div className='whitelistContainer'>
                          <div className="plansWrapper bgWhite">
                            <h4 className="textWhite bgBlue"><ProfileOutlined /><Space>Whitelisted Domains</Space></h4>
                            <div className="antdAccordionWrapper">
                              <ul className={sameDomain ? "same":"none"}>
                                {[...new Set(artists)].map(artist => (
                                  <li >{artist} <button className='delete' name={artist} onClick={removeListHandler}>
                                    Delete
                                  </button></li>
                                ))}
                              </ul>

                            </div>
                          </div>

                        </div>

                      </div>
                    </div>

                  </div>
                </div>
              </Col>
              <Col span={12}>

                <div className="plansWrapper bgWhite">
                  <h4 className="textWhite bgBlue"><ProfileOutlined /><Space>Blacklist Your Domain</Space></h4>
                  <div className="antdAccordionWrapper">

                    <div className="col-md-6">

                      <div className="domainInput">
                        <form>
                          <input type="url" placeholder="Enter the domain to be blacklisted (eg: example.com)" value={nameBlack}
                            onChange={blackListHandler} />
                           {errorBlacklist !== "Valid" ?<span style={{ color: "red" }}>{errorBlacklist}</span>: null}
                            {errorBlacklist === "Valid" ?<span style={{ color: "green" }}>{errorBlacklist}</span>: null}
                            {errorBlacklist === "Valid" ? <button className='applyBtn' type="button" onClick={() => {
                            if (nameBlack.length > 0) {
                              setSameDomain(false);
                              setSameDomainB(false);
                              setNameBlack('');
                              setArtistsBlack([
                                ...artistsBlack,
                                nameBlack
                              ]);
                              setTimeout(greet, 1000);
                            }

                          }}>Save</button>:null}
                        </form>

                        <div className='blacklistContainer'>
                          <div className="plansWrapper bgWhite">
                            <h4 className="textWhite bgBlue"><ProfileOutlined /><Space>Blacklisted Domains</Space></h4>
                            <div className="antdAccordionWrapper">
                              <ul>
                                {[...new Set(artistsBlack)].map(artistBlack => (
                                  <li >{artistBlack} <button className='delete' name={artistBlack} onClick={removeBlackListHandler}>
                                    Delete
                                  </button></li>
                                ))}
                              </ul>

                            </div>
                          </div>

                        </div>

                      </div>
                    </div>
                  </div>
                </div>
              </Col>

            </Row>
          </div>


        </div>
      </div>

    </div>
  );
}

export default BlockList;
