import Header from "../components/header";
import Login from "../components/login";

function Home () {
    return(
        <div className="homepage">
            <Header />
            <Login />
        </div>
    )
}

export default Home