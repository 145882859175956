
import { useState, useEffect } from 'react';
import { Space, Collapse, Slider, Switch, notification } from "antd";
import { ProfileOutlined, CheckOutlined, CloseOutlined, FieldTimeOutlined } from '@ant-design/icons';
import axios from "axios";
import ReadOnly from './readOnly';
const { Panel } = Collapse;





function Plans() {
  const [csPlan, setCsPlan] = useState(['ads', 'adult_content', 'banking', 'file_sharing_sites', 'hacking', 'malware_phishing', 'news_media', 'religion', 'sex_education', 'shopping', 'social_media', 'weapons_violance']);
  const [ohPlan, setOhPlan] = useState(['ads', 'adult_content', 'hacking', 'malware_phishing', 'sex_education', 'shopping', 'social_media', 'weapons_violance']);
  const [ftPlan, setFtPlan] = useState(['ads', 'adult_content', 'banking', 'hacking', 'malware_phishing', 'news_media', 'religion', 'search_email', 'sex_education', 'weapons_violance']);
  const [gmPlan, setGmPlan] = useState(['adictions', 'ads', 'adult_content', 'banking', 'file_sharing_sites', 'hacking', 'malware_phishing', 'sex_education', 'shopping', 'social_media', 'weapons_violance']);
  const [baPlan, setBaPlan] = useState(['adictions', 'ads', 'adult_content', 'antivirus', 'banking', 'file_sharing_sites', 'games_humor', 'hacking', 'health_home_garden', 'malware_phishing', 'news_media', 'religion', 'search_email', 'sex_education', 'shopping', 'social_media', 'weapons_violance']);
  const [shPlan, setShPlan] = useState(['ads', 'adult_content', 'banking', 'file_sharing_sites', 'games_humor', 'hacking', 'malware_phishing', 'news_media', 'religion', 'sex_education', 'shopping', 'social_media', 'weapons_violance']);
  const [scPlan, setScPlan] = useState([]);
  const [switches, setSwitches] = useState([false, false, false, false, false, false, false]);
  const [timeValue, setTimeValue] = useState([0, 23]);
  const [timeValue1, setTimeValue1] = useState([9, 17]);
  const [timeValue2, setTimeValue2] = useState([17, 20]);
  const [timeValue3, setTimeValue3] = useState([18, 20]);
  const [timeValue4, setTimeValue4] = useState([21, 23]);
  const [timeValue5, setTimeValue5] = useState([2, 23]);
  const [timeValue6, setTimeValue6] = useState([0, 23]);
  const [structure, setStructure] = useState({
    active: "",
    timebomb_active: false,
    timebomb_start: "",
    timebomb_end: "",
    kids_safe: false,
    homework_time: false,
    malware_protect: false,
    custom_base: [],
    custom2: {
      name: "",
      start_time: "",
      end_time: "",
      categories: []
    },
    custom3: {
      name: "",
      start_time: "",
      end_time: "",
      categories: []
    },
    whitelist: [],
    blacklist: []
  });
  console.log("api", structure)



  //useEffect

  useEffect(() => {
    const user = window.localStorage.getItem('user');
    const headers = {
      'ProfileID': user,
      'X-BuddyGuard-API-Key': 'MfLgaSi$cgnet03'
    };
    // Assume the API response is an array of boolean values indicating which switches should be turned on
    axios.get('https://bknd.cgnet.com.np/getDns', { headers })
      .then(response => {
        setStructure(response.data);
        const apiResponse = [response.data.custom2.name === 'child safety' || response.data.custom3.name === 'child safety' ? true : false, response.data.custom2.name === 'office hour' || response.data.custom3.name === 'office hour' ? true : false, response.data.custom2.name === 'study hour' || response.data.custom3.name === 'study hour' ? true : false, response.data.custom2.name === 'family time' || response.data.custom3.name === 'family time' ? true : false, response.data.custom2.name === 'goodnight mode' || response.data.custom3.name === 'goodnight mode' ? true : false, response.data.custom2.name === 'block all' || response.data.custom3.name === 'block all' ? true : false, response.data.custom2.name === 'scheduler' || response.data.custom3.name === 'scheduler' ? true : false];
        setSwitches(apiResponse);

        function timeStringToNumber(timeString) {
          const [hours, minutes] = timeString.split(":").map((n) => parseInt(n, 10));
          const totalMinutes = hours * 60 + minutes;
          return totalMinutes / 60;
        }

        if (response.data.custom2.name === 'child safety') {
          setTimeValue([timeStringToNumber(response.data.custom2.start_time), timeStringToNumber(response.data.custom2.end_time)])
        }
        if (response.data.custom3.name === 'child safety') {
          setTimeValue([timeStringToNumber(response.data.custom3.start_time), timeStringToNumber(response.data.custom3.end_time)])
        }
        if (response.data.custom2.name === 'office hour') {
          setTimeValue1([timeStringToNumber(response.data.custom2.start_time), timeStringToNumber(response.data.custom2.end_time)])
        }
        if (response.data.custom3.name === 'office hour') {
          setTimeValue1([timeStringToNumber(response.data.custom3.start_time), timeStringToNumber(response.data.custom3.end_time)])
        }
        if (response.data.custom2.name === 'study hour') {
          setTimeValue2([timeStringToNumber(response.data.custom2.start_time), timeStringToNumber(response.data.custom2.end_time)])
        }
        if (response.data.custom3.name === 'study hour') {
          setTimeValue2([timeStringToNumber(response.data.custom3.start_time), timeStringToNumber(response.data.custom3.end_time)])
        }
        if (response.data.custom2.name === 'family time') {
          setTimeValue3([timeStringToNumber(response.data.custom2.start_time), timeStringToNumber(response.data.custom2.end_time)])
        }
        if (response.data.custom3.name === 'family time') {
          setTimeValue3([timeStringToNumber(response.data.custom3.start_time), timeStringToNumber(response.data.custom3.end_time)])
        }
        if (response.data.custom2.name === 'goodnight mode') {
          setTimeValue4([timeStringToNumber(response.data.custom2.start_time), timeStringToNumber(response.data.custom2.end_time)])
        }
        if (response.data.custom3.name === 'goodnight mode') {
          setTimeValue4([timeStringToNumber(response.data.custom3.start_time), timeStringToNumber(response.data.custom3.end_time)])
        }
        if (response.data.custom2.name === 'block all') {
          setTimeValue5([timeStringToNumber(response.data.custom2.start_time), timeStringToNumber(response.data.custom2.end_time)])
        }
        if (response.data.custom3.name === 'block all') {
          setTimeValue5([timeStringToNumber(response.data.custom3.start_time), timeStringToNumber(response.data.custom3.end_time)])
        }
        if (response.data.custom2.name === 'scheduler') {
          setTimeValue6([timeStringToNumber(response.data.custom2.start_time), timeStringToNumber(response.data.custom2.end_time)])
        }
        if (response.data.custom3.name === 'scheduler') {
          setTimeValue6([timeStringToNumber(response.data.custom3.start_time), timeStringToNumber(response.data.custom3.end_time)])
        }
      })
      .catch(error => { });


  }, []);


  useEffect(() => {

    const user = window.localStorage.getItem('user');
    const headers = {
      'ProfileID': user,
      'X-BuddyGuard-API-Key': 'MfLgaSi$cgnet03'
    };

    axios.get('https://bknd.cgnet.com.np/getForm', { headers })
      .then(response => {
        setScPlan(JSON.parse(response.data[0].selectedCategories))
      })
      .catch(error => { });


  }, []);






  // time handlers for all 7 plans
  const onChangeSlider = (value, name) => {
    setTimeValue(value);
    if (structure.custom2.name === "child safety") {
      structure.custom2.start_time = value[0] < 10 ? String('0' + value[0] + ":15") : String(value[0] + ":00");
      structure.custom2.end_time = value[1] < 10 ? String('0' + value[1] + ":15") : String(value[1] + ":00");
    } else if (structure.custom3.name === "child safety") {
      structure.custom3.start_time = value[0] < 10 ? String('0' + value[0] + ":15") : String(value[0] + ":00");
      structure.custom3.end_time = value[1] < 10 ? String('0' + value[1] + ":15") : String(value[1] + ":00");
    }

    async function greet1() {
      try {
        const item = window.localStorage.getItem('user');

        const headers = {
          'ProfileID': item,
          'X-BuddyGuard-API-Key': 'MfLgaSi$cgnet03'
        };
        const response = await axios.post('https://bknd.cgnet.com.np/postDns', structure, { headers });
        // console.log("pppp",structure);
        if (response.status === 200) {
          // notification.open({
          //   message: 'Success',
          //   type: "success",
          //   description: 'post Successfully',
          //   onClick: () => {
          //     console.log('Notification Clicked!');
          //   },
          // });
          // console.log(response.data)
        }


      } catch (error) {
        notification.open({
          message: 'Error',
          type: "danger",
          description: 'Server error occured',
          onClick: () => {
            console.log('Notification Clicked!');
          },
        });
      }
    }

    setTimeout(greet1, 500);

  };
  const onChangeSlider1 = (value, name) => {
    setTimeValue1(value);

    if (structure.custom2.name === "office hour") {
      structure.custom2.start_time = value[0] < 10 ? String('0' + value[0] + ":15") : String(value[0] + ":00");
      structure.custom2.end_time = value[1] < 10 ? String('0' + value[1] + ":15") : String(value[1] + ":00");
    } else if (structure.custom3.name === "office hour") {
      structure.custom3.start_time = value[0] < 10 ? String('0' + value[0] + ":15") : String(value[0] + ":00");
      structure.custom3.end_time = value[1] < 10 ? String('0' + value[1] + ":15") : String(value[1] + ":00");
    }


    async function greet1() {
      try {
        const item = window.localStorage.getItem('user');

        const headers = {
          'ProfileID': item,
          'X-BuddyGuard-API-Key': 'MfLgaSi$cgnet03'
        };
        const response = await axios.post('https://bknd.cgnet.com.np/postDns', structure, { headers });
        // console.log("pppp",structure);
        if (response.status === 200) {
          // notification.open({
          //   message: 'Success',
          //   type: "success",
          //   description: 'post Successfully',
          //   onClick: () => {
          //     console.log('Notification Clicked!');
          //   },
          // });
          // console.log(response.data)
        }


      } catch (error) {
        notification.open({
          message: 'Error',
          type: "danger",
          description: 'Server error occured',
          onClick: () => {
            console.log('Notification Clicked!');
          },
        });
      }
    }

    setTimeout(greet1, 500);


  };
  const onChangeSlider2 = (value, name) => {
    setTimeValue2(value);
    if (structure.custom2.name === "study hour") {
      structure.custom2.start_time = value[0] < 10 ? String('0' + value[0] + ":15") : String(value[0] + ":00");
      structure.custom2.end_time = value[1] < 10 ? String('0' + value[1] + ":15") : String(value[1] + ":00");
    } else if (structure.custom3.name === "study hour") {
      structure.custom3.start_time = value[0] < 10 ? String('0' + value[0] + ":15") : String(value[0] + ":00");
      structure.custom3.end_time = value[1] < 10 ? String('0' + value[1] + ":15") : String(value[1] + ":00");
    }


    async function greet1() {
      try {
        const item = window.localStorage.getItem('user');

        const headers = {
          'ProfileID': item,
          'X-BuddyGuard-API-Key': 'MfLgaSi$cgnet03'
        };
        const response = await axios.post('https://bknd.cgnet.com.np/postDns', structure, { headers });
        // console.log("pppp",structure);
        if (response.status === 200) {
          // notification.open({
          //   message: 'Success',
          //   type: "success",
          //   description: 'post Successfully',
          //   onClick: () => {
          //     console.log('Notification Clicked!');
          //   },
          // });
          // console.log(response.data)
        }


      } catch (error) {
        notification.open({
          message: 'Error',
          type: "danger",
          description: 'Server error occured',
          onClick: () => {
            console.log('Notification Clicked!');
          },
        });
      }
    }

    setTimeout(greet1, 500);


  };
  const onChangeSlider3 = (value, name) => {
    setTimeValue3(value);
    if (structure.custom2.name === "family time") {
      structure.custom2.start_time = value[0] < 10 ? String('0' + value[0] + ":15") : String(value[0] + ":00");
      structure.custom2.end_time = value[1] < 10 ? String('0' + value[1] + ":15") : String(value[1] + ":00");
    } else if (structure.custom3.name === "family time") {
      structure.custom3.start_time = value[0] < 10 ? String('0' + value[0] + ":15") : String(value[0] + ":00");
      structure.custom3.end_time = value[1] < 10 ? String('0' + value[1] + ":15") : String(value[1] + ":00");
    }


    async function greet1() {
      try {
        const item = window.localStorage.getItem('user');

        const headers = {
          'ProfileID': item,
          'X-BuddyGuard-API-Key': 'MfLgaSi$cgnet03'
        };
        const response = await axios.post('https://bknd.cgnet.com.np/postDns', structure, { headers });
        // console.log("pppp",structure);
        if (response.status === 200) {
          // notification.open({
          //   message: 'Success',
          //   type: "success",
          //   description: 'post Successfully',
          //   onClick: () => {
          //     console.log('Notification Clicked!');
          //   },
          // });
          // console.log(response.data)
        }


      } catch (error) {
        notification.open({
          message: 'Error',
          type: "danger",
          description: 'Server error occured',
          onClick: () => {
            console.log('Notification Clicked!');
          },
        });
      }
    }

    setTimeout(greet1, 500);



  };
  const onChangeSlider4 = (value, name) => {
    setTimeValue4(value);
    if (structure.custom2.name === "goodnight mode") {
      structure.custom2.start_time = value[0] < 10 ? String('0' + value[0] + ":15") : String(value[0] + ":00");
      structure.custom2.end_time = value[1] < 10 ? String('0' + value[1] + ":15") : String(value[1] + ":00");
    } else if (structure.custom3.name === "goodnight mode") {
      structure.custom3.start_time = value[0] < 10 ? String('0' + value[0] + ":15") : String(value[0] + ":00");
      structure.custom3.end_time = value[1] < 10 ? String('0' + value[1] + ":15") : String(value[1] + ":00");
    }

    async function greet1() {
      try {
        const item = window.localStorage.getItem('user');

        const headers = {
          'ProfileID': item,
          'X-BuddyGuard-API-Key': 'MfLgaSi$cgnet03'
        };
        const response = await axios.post('https://bknd.cgnet.com.np/postDns', structure, { headers });
        // console.log("pppp",structure);
        if (response.status === 200) {
          // notification.open({
          //   message: 'Success',
          //   type: "success",
          //   description: 'post Successfully',
          //   onClick: () => {
          //     console.log('Notification Clicked!');
          //   },
          // });
          // console.log(response.data)
        }


      } catch (error) {
        notification.open({
          message: 'Error',
          type: "danger",
          description: 'Server error occured',
          onClick: () => {
            console.log('Notification Clicked!');
          },
        });
      }
    }

    setTimeout(greet1, 500);

  };
  const onChangeSlider5 = (value, name) => {
    setTimeValue5(value);
    if (structure.custom2.name === "block all") {
      structure.custom2.start_time = value[0] < 10 ? String('0' + value[0] + ":15") : String(value[0] + ":00");
      structure.custom2.end_time = value[1] < 10 ? String('0' + value[1] + ":15") : String(value[1] + ":00");
    } else if (structure.custom3.name === "block all") {
      structure.custom3.start_time = value[0] < 10 ? String('0' + value[0] + ":15") : String(value[0] + ":00");
      structure.custom3.end_time = value[1] < 10 ? String('0' + value[1] + ":15") : String(value[1] + ":00");
    }


    async function greet1() {
      try {
        const item = window.localStorage.getItem('user');

        const headers = {
          'ProfileID': item,
          'X-BuddyGuard-API-Key': 'MfLgaSi$cgnet03'
        };
        const response = await axios.post('https://bknd.cgnet.com.np/postDns', structure, { headers });
        // console.log("pppp",structure);
        if (response.status === 200) {
          // notification.open({
          //   message: 'Success',
          //   type: "success",
          //   description: 'post Successfully',
          //   onClick: () => {
          //     console.log('Notification Clicked!');
          //   },
          // });
          // console.log(response.data)
        }


      } catch (error) {
        notification.open({
          message: 'Error',
          type: "danger",
          description: 'Server error occured',
          onClick: () => {
            console.log('Notification Clicked!');
          },
        });
      }
    }

    setTimeout(greet1, 500);



  };
  const onChangeSlider6 = (value, name) => {
    setTimeValue6(value);
    if (structure.custom2.name === "scheduler") {
      structure.custom2.start_time = value[0] < 10 ? String('0' + value[0] + ":15") : String(value[0] + ":00");
      structure.custom2.end_time = value[1] < 10 ? String('0' + value[1] + ":15") : String(value[1] + ":00");
    } else if (structure.custom3.name === "scheduler") {
      structure.custom3.start_time = value[0] < 10 ? String('0' + value[0] + ":15") : String(value[0] + ":00");
      structure.custom3.end_time = value[1] < 10 ? String('0' + value[1] + ":15") : String(value[1] + ":00");
    }


    async function greet1() {
      try {
        const item = window.localStorage.getItem('user');

        const headers = {
          'ProfileID': item,
          'X-BuddyGuard-API-Key': 'MfLgaSi$cgnet03'
        };
        const response = await axios.post('https://bknd.cgnet.com.np/postDns', structure, { headers });
        // console.log("pppp",structure);
        if (response.status === 200) {
          // notification.open({
          //   message: 'Success',
          //   type: "success",
          //   description: 'post Successfully',
          //   onClick: () => {
          //     console.log('Notification Clicked!');
          //   },
          // });
          // console.log(response.data)
        }


      } catch (error) {
        notification.open({
          message: 'Error',
          type: "danger",
          description: 'Server error occured',
          onClick: () => {
            console.log('Notification Clicked!');
          },
        });
      }
    }

    setTimeout(greet1, 500);



  };




  const handleSwitchToggle = (index, id) => {
    const numSwitchesOn = switches.filter((value) => value).length;
    if (switches[index]) {
      // If the switch is already on, turn it off
      setSwitches((prevState) => {
        const newState = [...prevState];
        newState[index] = false;
        return newState;
      });



      function removeValue(obj, value) {

        // console.log("object", obj)
        for (let key in obj) {
          if (Array.isArray(obj[key]) && obj[key].includes(value)) {
            obj[key].splice(obj[key].indexOf(value), 1);
            return true;
          } else if (Array.isArray(value) && JSON.stringify(obj[key]) === JSON.stringify(value)) {
            obj[key] = [];
            return true;
          } else if (obj[key] === value) {
            obj[key] = "";
            return true;
          } else if (typeof obj[key] === "object") {
            if (removeValue(obj[key], value)) {
              return true;
            }
          }
        }

        return false;


      }


      if (index === 0) {
        removeValue(structure, "child safety");
        removeValue(structure, csPlan);
        if (Number(String(timeValue[0])) < 10) {
          removeValue(structure, String('0' + timeValue[0] + ":15"));
        } else {
          removeValue(structure, String(timeValue[0] + ":00"));
        }
        if (Number(String(timeValue[1])) < 10) {
          removeValue(structure, String('0' + timeValue[1] + ":15"));
        } else {
          removeValue(structure, String(timeValue[1] + ":00"));
        }
      }

      if (index === 1) {
        removeValue(structure, "office hour");
        removeValue(structure, ohPlan);
        if (Number(String(timeValue1[0])) < 10) {
          removeValue(structure, String('0' + timeValue1[0] + ":15"));
        } else {
          removeValue(structure, String(timeValue1[0] + ":00"));
        }
        if (Number(String(timeValue1[1])) < 10) {
          removeValue(structure, String('0' + timeValue1[1] + ":15"));
        } else {
          removeValue(structure, String(timeValue1[1] + ":00"));
        }
      }

      if (index === 2) {
        removeValue(structure, "study hour");
        removeValue(structure, shPlan);
        if (Number(String(timeValue2[0])) < 10) {
          removeValue(structure, String('0' + timeValue2[0] + ":15"));
        } else {
          removeValue(structure, String(timeValue2[0] + ":00"));
        }
        if (Number(String(timeValue2[1])) < 10) {
          removeValue(structure, String('0' + timeValue2[1] + ":15"));
        } else {
          removeValue(structure, String(timeValue2[1] + ":00"));
        }
      }


      if (index === 3) {
        removeValue(structure, "family time");
        removeValue(structure, ftPlan);
        if (Number(String(timeValue3[0])) < 10) {
          removeValue(structure, String('0' + timeValue3[0] + ":15"));
        } else {
          removeValue(structure, String(timeValue3[0] + ":00"));
        }
        if (Number(String(timeValue3[1])) < 10) {
          removeValue(structure, String('0' + timeValue3[1] + ":15"));
        } else {
          removeValue(structure, String(timeValue3[1] + ":00"));
        }
      }


      if (index === 4) {
        removeValue(structure, "goodnight mode");
        removeValue(structure, gmPlan);
        if (Number(String(timeValue4[0])) < 10) {
          removeValue(structure, String('0' + timeValue4[0] + ":15"));
        } else {
          removeValue(structure, String(timeValue4[0] + ":00"));
        }
        if (Number(String(timeValue4[1])) < 10) {
          removeValue(structure, String('0' + timeValue4[1] + ":15"));
        } else {
          removeValue(structure, String(timeValue4[1] + ":00"));
        }
      }


      if (index === 5) {
        removeValue(structure, "block all");
        removeValue(structure, baPlan);
        if (Number(String(timeValue5[0])) < 10) {
          removeValue(structure, String('0' + timeValue5[0] + ":15"));
        } else {
          removeValue(structure, String(timeValue5[0] + ":00"));
        }
        if (Number(String(timeValue5[1])) < 10) {
          removeValue(structure, String('0' + timeValue5[1] + ":15"));
        } else {
          removeValue(structure, String(timeValue5[1] + ":00"));
        }
      }

      if (index === 6) {
        removeValue(structure, "scheduler");
        removeValue(structure, scPlan);
        if (Number(String(timeValue6[0])) < 10) {
          removeValue(structure, String('0' + timeValue6[0] + ":15"));
        } else {
          removeValue(structure, String(timeValue6[0] + ":00"));
        }
        if (Number(String(timeValue6[1])) < 10) {
          removeValue(structure, String('0' + timeValue6[1] + ":15"));
        } else {
          removeValue(structure, String(timeValue6[1] + ":00"));
        }
      }

      async function greet1() {
        try {
          const item = window.localStorage.getItem('user');

          const headers = {
            'ProfileID': item,
            'X-BuddyGuard-API-Key': 'MfLgaSi$cgnet03'
          };
          const response = await axios.post('https://bknd.cgnet.com.np/postDns', structure, { headers });
          // console.log("pppp",structure);
          if (response.status === 200) {
            // notification.open({
            //   message: 'Success',
            //   type: "success",
            //   description: 'post Successfully',
            //   onClick: () => {
            //     console.log('Notification Clicked!');
            //   },
            // });
            // console.log(response.data)
          }


        } catch (error) {
          notification.open({
            message: 'Error',
            type: "danger",
            description: 'Server error occured',
            onClick: () => {
              console.log('Notification Clicked!');
            },
          });
        }
      }

      setTimeout(greet1, 500);


    } else if (numSwitchesOn < 2) {
      // If less than two switches are currently on, turn the selected switch on
      setSwitches((prevState) => {
        const newState = [...prevState];
        newState[index] = true;
        return newState;
      });


      // update custom2 name if it is empty, otherwise update custom3 name
      function setNameStr() {
        if (!structure.custom2.name) {
          structure.custom2.name = id;
          if (id === 'child safety') {
            structure.custom2.start_time = timeValue[0] < 10 ? String('0' + timeValue[0] + ":15") : String(timeValue[0] + ":00");
            structure.custom2.end_time = timeValue[1] < 10 ? String('0' + timeValue[1] + ":15") : String(timeValue[1] + ":00");
            structure.custom2.categories = csPlan;
          }
          else if (id === 'office hour') {
            structure.custom2.start_time = timeValue1[0] < 10 ? String('0' + timeValue1[0] + ":15") : String(timeValue1[0] + ":00");
            structure.custom2.end_time = timeValue1[1] < 10 ? String('0' + timeValue1[1] + ":15") : String(timeValue1[1] + ":00");
            structure.custom2.categories = ohPlan;
          }
          else if (id === 'study hour') {
            structure.custom2.start_time = timeValue2[0] < 10 ? String('0' + timeValue2[0] + ":15") : String(timeValue2[0] + ":00");
            structure.custom2.end_time = timeValue2[1] < 10 ? String('0' + timeValue2[1] + ":15") : String(timeValue2[1] + ":00");
            structure.custom2.categories = shPlan;
          }
          else if (id === 'family time') {
            structure.custom2.start_time = timeValue3[0] < 10 ? String('0' + timeValue3[0] + ":15") : String(timeValue3[0] + ":00");
            structure.custom2.end_time = timeValue3[1] < 10 ? String('0' + timeValue3[1] + ":15") : String(timeValue3[1] + ":00");
            structure.custom2.categories = ftPlan;
          }
          else if (id === 'goodnight mode') {
            structure.custom2.start_time = timeValue4[0] < 10 ? String('0' + timeValue4[0] + ":15") : String(timeValue4[0] + ":00");
            structure.custom2.end_time = timeValue4[1] < 10 ? String('0' + timeValue4[1] + ":15") : String(timeValue4[1] + ":00");
            structure.custom2.categories = gmPlan;
          }
          else if (id === 'block all') {
            structure.custom2.start_time = timeValue5[0] < 10 ? String('0' + timeValue5[0] + ":15") : String(timeValue5[0] + ":00");
            structure.custom2.end_time = timeValue5[1] < 10 ? String('0' + timeValue5[1] + ":15") : String(timeValue5[1] + ":00");
            structure.custom2.categories = baPlan;
          }
          else if (id === 'scheduler') {
            structure.custom2.start_time = timeValue6[0] < 10 ? String('0' + timeValue6[0] + ":15") : String(timeValue6[0] + ":00");
            structure.custom2.end_time = timeValue6[1] < 10 ? String('0' + timeValue6[1] + ":15") : String(timeValue6[1] + ":00");
            structure.custom2.categories = scPlan;
          }
        } else {
          structure.custom3.name = id;
          if (id === 'child safety') {
            structure.custom3.start_time = timeValue[0] < 10 ? String('0' + timeValue[0] + ":15") : String(timeValue[0] + ":00");
            structure.custom3.end_time = timeValue[1] < 10 ? String('0' + timeValue[1] + ":15") : String(timeValue[1] + ":00");
            structure.custom3.categories = csPlan;
          }
          else if (id === 'office hour') {
            structure.custom3.start_time = timeValue1[0] < 10 ? String('0' + timeValue1[0] + ":15") : String(timeValue1[0] + ":00");
            structure.custom3.end_time = timeValue1[1] < 10 ? String('0' + timeValue1[1] + ":15") : String(timeValue1[1] + ":00");
            structure.custom3.categories = ohPlan;
          }
          else if (id === 'study hour') {
            structure.custom3.start_time = timeValue2[0] < 10 ? String('0' + timeValue2[0] + ":15") : String(timeValue2[0] + ":00");
            structure.custom3.end_time = timeValue2[1] < 10 ? String('0' + timeValue2[1] + ":15") : String(timeValue2[1] + ":00");
            structure.custom3.categories = shPlan;
          }
          else if (id === 'family time') {
            structure.custom3.start_time = timeValue3[0] < 10 ? String('0' + timeValue3[0] + ":15") : String(timeValue3[0] + ":00");
            structure.custom3.end_time = timeValue3[1] < 10 ? String('0' + timeValue3[1] + ":15") : String(timeValue3[1] + ":00");
            structure.custom3.categories = ftPlan;
          }
          else if (id === 'goodnight mode') {
            structure.custom3.start_time = timeValue4[0] < 10 ? String('0' + timeValue4[0] + ":15") : String(timeValue4[0] + ":00");
            structure.custom3.end_time = timeValue4[1] < 10 ? String('0' + timeValue4[1] + ":15") : String(timeValue4[1] + ":00");
            structure.custom3.categories = gmPlan;
          }
          else if (id === 'block all') {
            structure.custom3.start_time = timeValue5[0] < 10 ? String('0' + timeValue5[0] + ":15") : String(timeValue5[0] + ":00");
            structure.custom3.end_time = timeValue5[1] < 10 ? String('0' + timeValue5[1] + ":15") : String(timeValue5[1] + ":00");
            structure.custom3.categories = baPlan;
          }
          else if (id === 'scheduler') {
            structure.custom3.start_time = timeValue6[0] < 10 ? String('0' + timeValue6[0] + ":15") : String(timeValue6[0] + ":00");
            structure.custom3.end_time = timeValue6[1] < 10 ? String('0' + timeValue6[1] + ":15") : String(timeValue6[1] + ":00");
            structure.custom3.categories = scPlan;
          }
        }
      }
      setNameStr();



      async function greet1() {
        try {
          const item = window.localStorage.getItem('user');

          const headers = {
            'ProfileID': item,
            'X-BuddyGuard-API-Key': 'MfLgaSi$cgnet03'
          };
          const response = await axios.post('https://bknd.cgnet.com.np/postDns', structure, { headers });
          // console.log("pppp",structure);
          if (response.status === 200) {
            // notification.open({
            //   message: 'Success',
            //   type: "success",
            //   description: 'Update Successful',
            //   onClick: () => {
            //     console.log('Notification Clicked!');
            //   },
            // });
            // console.log(response.data)
          }


        } catch (error) {
          notification.open({
            message: 'Error',
            type: "danger",
            description: 'Upto two plans can be selected at a time',
            onClick: () => {
              console.log('Notification Clicked!');
            },
          });
        }
      }

      setTimeout(greet1, 500);
    } else {
      if (switches.includes(id)) {

      } else {
        notification.open({
          message: 'Warning',
          type: "warning",
          description:
            'Only two of the plans can be activated.',
          onClick: () => {
            console.log('Notification Clicked!');
          },
        });
      }
    }


  };

  // end of handlers
  const childDataCs = <>
    <Slider range={{ draggableTrack: true, }} defaultValue={timeValue} min={0}
      max={23} onChange={(value) => onChangeSlider(value, 'cs')} />
  </>;
  const childDataOh = <>
    <Slider range={{ draggableTrack: true, }} defaultValue={timeValue1} min={0}
      max={23} onChange={(value) => onChangeSlider1(value, 'oh')} />
  </>;
  const childDataSh = <>
    <Slider range={{ draggableTrack: true, }} defaultValue={timeValue2} min={0}
      max={23} onChange={(value) => onChangeSlider2(value, 'sh')} />
  </>;
  const childDataFt = <>
    <Slider range={{ draggableTrack: true, }} defaultValue={timeValue3} min={0}
      max={23} onChange={(value) => onChangeSlider3(value, 'ft')} />
  </>;
  const childDataGm = <>
    <Slider range={{ draggableTrack: true, }} defaultValue={timeValue4} min={0}
      max={23} onChange={(value) => onChangeSlider4(value, 'gm')} />
  </>;
  const childDataBa = <>
    <Slider range={{ draggableTrack: true, }} defaultValue={timeValue5} min={0}
      max={23} onChange={(value) => onChangeSlider5(value, 'ba')} />
  </>;

  const childDataSc = <>
    <Slider range={{ draggableTrack: true, }} defaultValue={timeValue6} min={0}
      max={23} onChange={(value) => onChangeSlider6(value, 'sc')} />
  </>;




  return (

    <div className="plansWrapper bgWhite">
      <div className='d-none'>
        <ReadOnly />
      </div>
      <h4 className="textWhite bgBlue"><ProfileOutlined /><Space>Quick Plans</Space></h4>
      <div className="antdAccordionWrapper">
        <Collapse accordion>
          <Panel header={
            <> <div >

              <div>
                <Switch title='Turn on/off for child safety' checkedChildren={<CheckOutlined />} unCheckedChildren={<CloseOutlined />} checked={switches[0]} onChange={() => handleSwitchToggle(0, "child safety")} id="csSwitch" />
                <div className="round csBg"></div>
                <p>Child Safety</p>
              </div>
              <div>
                {/* <InfoCircleOutlined /> */}
                <FieldTimeOutlined title="Affected categories ('ads','adult_content','banking','file_sharing_sites','hacking','malware_phishing','news_media','religion','sex_education','shopping','social_media','weapons_violance')" />
              </div>

            </div></>
          } key="1">
            <p>{childDataCs}</p>
          </Panel>
          <Panel header={
            <> <div>
              <div>
                <Switch title='Turn switch on/off for office hours' checkedChildren={<CheckOutlined />} unCheckedChildren={<CloseOutlined />} checked={switches[1]} onChange={() => handleSwitchToggle(1, 'office hour')} id="ohSwitch" />
                <div className="round ohBg"></div>
                <p>Office Hours</p>
              </div>
              <div>
                {/* <InfoCircleOutlined /> */}
                <FieldTimeOutlined title="Affected categories ('ads','adult_content','hacking','malware_phishing','sex_education','shopping','social_media','weapons_violance')" />
              </div>
            </div></>
          } key="2">
            <div>{childDataOh}</div>
          </Panel>
          <Panel header={
            <> <div>
              <div>
                <Switch title='Turn switch on/off for study hour' checkedChildren={<CheckOutlined />} unCheckedChildren={<CloseOutlined />} checked={switches[2]} onChange={() => handleSwitchToggle(2, 'study hour')} id="shSwitch" />
                <div className="round shBg"></div>
                <p>Study Hour</p>
              </div>
              <div>
                {/* <InfoCircleOutlined /> */}
                <FieldTimeOutlined title=" Affected categories ('ads','adult_content','banking','file_sharing_sites','games_humor','hacking','malware_phishing','news_media','religion','sex_education','shopping','social_media','weapons_violance')" />
              </div>
            </div></>
          } key="3">
            <div>{childDataSh}</div>
          </Panel>
          <Panel header={
            <> <div>
              <div>
                <Switch title='Turn switch on/off for family time' checkedChildren={<CheckOutlined />} unCheckedChildren={<CloseOutlined />} checked={switches[3]} onChange={() => handleSwitchToggle(3, 'family time')} id="ftSwitch" />
                <div className="round ftBg"></div>
                <div>Family Time</div>
              </div>
              <div>
                {/* <InfoCircleOutlined /> */}
                <FieldTimeOutlined title="Affected categories ('ads','adult_content','banking','hacking','malware_phishing','news_media','religion','search_email','sex_education','weapons_violance')" />
              </div>
            </div></>
          } key="4">
            <p>{childDataFt}</p>
          </Panel>
          <Panel header={
            <> <div>
              <div>
                <Switch title='Turn switch on/off for goodnight mode' checkedChildren={<CheckOutlined />} unCheckedChildren={<CloseOutlined />} checked={switches[4]} onChange={() => handleSwitchToggle(4, 'goodnight mode')} id="gmSwitch" />
                <div className="round gmBg"></div>
                <p>Goodnight Mode</p>
              </div>
              <div>
                {/* <InfoCircleOutlined /> */}
                <FieldTimeOutlined title="Affected categories ('adictions','ads','adult_content','banking','file_sharing_sites','hacking','malware_phishing','sex_education','shopping','social_media','weapons_violance')" />
              </div>
            </div></>
          } key="5">
            <div>{childDataGm}</div>
          </Panel>
          <Panel header={
            <> <div>
              <div>
                <Switch title='Turn switch on/off for block all' checkedChildren={<CheckOutlined />} unCheckedChildren={<CloseOutlined />} checked={switches[5]} onChange={() => handleSwitchToggle(5, 'block all')} id="baSwitch" />
                <div className="round cpBg"></div>
                <p>Block All</p>
              </div>
              <div>
                {/* <InfoCircleOutlined /> */}
                <FieldTimeOutlined title="Affected categories ('adictions','ads','adult_content','antivirus','banking','file_sharing_sites','games_humor','hacking','health_home_garden','malware_phishing','news_media','religion','search_email','sex_education','shopping','social_media','weapons_violance')" />
              </div>
            </div></>
          } key="6">
            <div>{childDataBa}</div>
          </Panel>
          {scPlan.length !== 0 ? <Panel header={
            <> <div>
              <div>
                <Switch title='Turn switch on/off for scheduler activation' checkedChildren={<CheckOutlined />} unCheckedChildren={<CloseOutlined />} checked={switches[6]} onChange={() => handleSwitchToggle(6, 'scheduler')} id="scSwitch" />
                <div className="round scBg"></div>
                <p>My Custom Scheduler</p>
              </div>
              <div>
                {/* <InfoCircleOutlined /> */}
                <FieldTimeOutlined title='Affected categories ( custom categories selected from advance menu )' />
              </div>
            </div></>
          } key="7">
            <div>{childDataSc}</div>
          </Panel> : null}

        </Collapse>
      </div>
    </div>
  )
}
export default Plans